@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}

:root {
  --color-bg: #ffffff;
  --color-white: #fff;
  --color-black: #111111;
  --color-secondary: #cafc43;
  --color-primary: #3db17f;

  --color-accent: #eef1e5;
}

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Readex Pro", "Outfit", sans-serif;
  background-color: var(--color-bg);
  line-height: 1.7;
}
.primary_color {
  color: var(--color-primary);
}
.black_color {
  color: var(--color-black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
.light {
  font-weight: 300;
}
.mediam {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.paddingTop {
  padding-top: 120px;
}
.no-margin {
  margin: 0;
}
.loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 100vh;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.direction {
  flex-direction: column;
}
.space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

section {
  margin-top: 104px;
}
/* ////////////////////Image Overlay////////////// */
.image {
  margin-bottom: 24px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  border-radius: 15px;
}

.project_pictures {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image:hover .project_pictures {
  transform: scale(1.2);
}

.image__overlay {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  color: var(--color-white);
  font-family: "Readex Pro";
  opacity: 0;
  transition: opacity 0.25s;
}

.image:hover .image__overlay {
  opacity: 1;
}

.image__title {
  font-size: 2em;
  font-weight: bold;
}

.image__description {
  font-size: 1.25em;
  margin-top: 0.25em;
}

/* //////////////////////////Header////////////////////// */
.header_bg {
  z-index: 100;
  position: absolute;
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 57px;
  width: 132px;
}
.list_gap {
  gap: 32px;
}
nav ol a {
  background: transparent;

  border-radius: 50%;
  display: flex;
  color: var(--color-black);
}
nav ol a:hover {
  color: #111111;
  text-decoration: none;
}
nav ol a.active {
  color: var(--color-secondary);
}
/* //////////////////////////Footer////////////////// */
.footer_bg {
  width: 100%;

  border-radius: 20px;

  margin-top: 104px;
  background-color: var(--color-accent);
}
.footer_heading {
  font-family: "Outfit";
  font-size: 55px;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
}
.footer_1 {
  padding: 40px;

  grid-column: 1 / span 14;
}
.inquires_btn {
  width: 263px;
  height: 54px;
  background-color: var(--color-secondary);
  border-radius: 10px;
  border: 1px solid var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111111;
}
.inquires_btn:hover {
  text-decoration: none !important;
  color: #111111;
}
.footer_btn {
  position: relative;

  grid-column: 15 / span 15;
}
.social_heading {
  padding-left: 8px;
  font-family: "Outfit";
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 0px;
}
.icons {
  width: 28px;
  height: 27.38px;
  color: var(--color-primary);
}
.icon_top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 24px;
}
.icon-box {
  max-width: 170px;
}
.margin_top {
  margin-top: 24px;
}
.margin_bottom {
  margin-bottom: 24px;
}
.bottom_line {
  border: 1px solid var(--color-black);
}
.reserve_heading {
  font-family: "Outfit";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.reserve_gap {
  gap: 189px;
}
/* //////////////// HOME PAGE/////////////////////////////// */

/* ---hero--- */

.hero_heading {
  font-family: "Readex Pro";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
}

.hero_paragraph {
  margin-top: 16px;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 24px;
}
.banner-btn-flex {
  display: flex;
}
.hire_btn {
  width: 175px;
  height: 54px;
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);

  border-radius: 10px;
  background: var(--color-secondary);
  border: 1px;
}
.gap_btn {
  gap: 16px;
}
.work_btn {
  width: 216px;
  height: 54px;
  top: 636px;
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  background-color: var(--color-bg);
  color: var(--color-black);
  border-radius: 10px;
  border: 1px solid var(--color-black);
}
.profile_me {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -----------Project section----------- */

.project_heading {
  font-family: "Readex Pro";
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 48px;
}

/* ----------Process Section------------ */
.process_grid {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
}
.process_bg {
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 20px;
  margin-bottom: 31px;
  background-size: cover;
  background-color: var(--color-accent);
  background-image: url("./assets/Images/process_bg.svg");
}
.process_heading {
  font-family: "Readex Pro";
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 32px;
}

.process_paragraph {
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
}

.process_1 {
  padding: 113px 0px 88px 124px;
  grid-column: 1 / span 11;
}
.process_img {
  position: relative;
  align-self: flex-end;
  grid-column: 13 / span 15;
}
.process_img2 {
  position: relative;
  align-self: flex-end;
  grid-column: 14 / span 15;
}
.discovery_img {
  left: 70%;
  position: absolute;
  height: 179px;
  width: 557px;
  top: -38%;
}
.segment_img {
  height: 228px;
  width: 114px;

  position: absolute;
  left: -4%;
}

.execute {
  position: absolute;
  left: -6%;
  top: 85%;
}

/* ---------Summary section -----------------
*/

.summary_heading {
  font-family: "Readex Pro";
  font-size: 55px;
  font-weight: 500;
  line-height: 69px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c1d1d;
}

.experience_box {
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 48px 12px #0000000d;

  flex-direction: column;
  border: 1px solid var(--color-bg);
  border-radius: 20px;
}
.industory_box {
  flex-direction: column;
  width: 100%;
  padding: 36px;
  box-shadow: 0px 0px 48px 12px #0000000d;

  border: 1px solid var(--color-bg);
  border-radius: 20px;
}
.year {
  font-family: "Readex Pro";
  font-size: 128px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-primary);
}

.experience {
  font-family: "Readex Pro";
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-black);
}

.industory_heading {
  font-family: "Readex Pro";
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c1d1d;
}
.industory_paragraph {
  font-family: "Readex Pro";
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #1c1d1d;
  margin-top: 4px;
  margin-bottom: 24px;
}
.categories {
  font-family: "Readex Pro";
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c1d1d;
  margin-bottom: 0px;
}
.many-more-div {
  display: flex;
  align-items: center;
}
.many-more {
  color: #1c1d1d;
  font-family: "Readex Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0px;
}
.categories_div {
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #343434;
  opacity: 0.5;
  background: #fff;
}
.categories_box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.projects_box {
  flex-direction: column;
  width: 100%;
  padding: 36px;
  box-shadow: 0px 0px 48px 12px #0000000d;

  border: 1px solid var(--color-bg);
  border-radius: 20px;
}
.items_gap {
  gap: 26px;
}

.items {
  font-family: "Readex Pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #1c1d1d;
  margin-bottom: 45px;
}

ul li::before {
  content: "\2022";
  color: var(--color-primary);
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 17px;
}
.grid_box {
  column-gap: 20px;
  display: grid;
  grid-template-rows: 1fr;
  margin-top: 48px;
  grid-template-columns: repeat(4, 1fr);
}

.grid_third {
  grid-column: 3 / span 4;
}

/* /////////////////////////////////Digit Page////////////////////////////*/

/* --------------------HERO---------------------------*/

.hero_banner {
  background-image: url("./assets//Images/BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
}
.digit_hero_heading {
  font-family: "Readex Pro";
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-black);
}
.digit_hero_paragraph {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 80px;
  margin-top: 16px;
  color: var(--color-black);
}

.diggit_section {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-color: var(--color-accent);
  background-image: url("./assets//Images/bg_diggit.svg");
}
.grid_banner {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.diggit_box {
  grid-column: 1 / span 5;
  align-self: flex-end;
}
.banner-grid {
  grid-column: 6 / sapn 8;
  align-self: end;
  position: relative;
  top: 57%;
}
.overview_heading {
  margin-bottom: 16px;
  margin-top: 32px;
  font-family: "Readex Pro";
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.overview_paragraph {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e;
}
.overview_grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.overveiw_items {
  grid-column: 1 / span 5;
}
.link-space:hover {
  text-decoration: none;
}

/* ----------------------Discovery section----------------------------------------- */

.discovery_heading {
  font-family: "Readex Pro";
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
}
.discovery_paragraph {
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  color: #1e1e1e;
}
.competitors_heading {
  font-family: Readex Pro;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-bottom: 24px;
}
.comp_list {
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 19px;
}
.margin_list {
  margin-bottom: 58px;
}

.discovery_box {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: flex-start;
}
.discovery_grid {
  grid-column: 1 / span 5;
}

.list_grid {
  grid-column: 8 / span 10;
}

.discovery_heading2 {
  margin-bottom: 24px;
  font-family: Readex Pro;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #111111;
}
.discovery_img-grid {
  grid-column-start: 6;
  position: absolute;
  align-self: end;
  left: 43%;
  top: 57%;
}

/* ---------------------Ideation section-------------- */

.ideation_bg {
  background-color: var(--color-accent);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.ideation_heading {
  font-family: "Readex Pro";
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 134px;
  margin-bottom: 18px;
}
.prototype_grid {
  margin-bottom: 122px;
  align-items: center;
  display: flex;

  overflow: scroll;
  align-items: center;
}
.prototype_img {
  height: 469.19px;
}

/* ---------------------------Design----------------- */

.design_heading {
  margin-bottom: 30px;
  font-family: "Readex Pro";
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}

.design_subheading {
  margin-bottom: 16px;
  font-family: "Readex Pro";
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.design_paragraph {
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e;
}
.design_grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: flex-start;
  justify-content: center;
}
.margin_twentynine {
  margin-top: 29px;
}

.design_item {
  grid-column: 1 / span 4;
}

.visual_img {
  margin-top: 40px;
}

@media (max-width: 550px) {
  .hero_heading {
    font-size: 40px;
    line-height: 150%;
  }
  .hero_paragraph {
    font-size: 16px;
    line-height: 150%;
  }
  .discovery_img {
    width: 100%;
    height: auto;
    left: 0px;
    z-index: -1;
  }
  .grid_box {
    display: block;
  }
  .process_grid {
    display: block;
  }
  .banner-btn-flex {
    display: block;
  }
  .mobile-col-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .hire_btn {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    height: auto;
    padding: 16px;
  }
  .work_btn {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 16px;
  }
  .profile-img {
    width: 75%;
    margin-bottom: 40px;
  }
  .project_heading {
    font-size: 40px;
    line-height: 150%;
  }
  section {
    margin-top: 80px;
  }
  .process_1 {
    padding: 40px;
  }
  .process_img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .process_heading {
    font-size: 32px;
    line-height: 150%;
  }
  .process_paragraph {
    font-size: 16px;
    line-height: 150%;
  }
  .segment_img,
  .execute_layer_1 {
    display: none;
  }
  .summary_heading {
    font-size: 40px;
    line-height: 150%;
  }
  .experience_box,
  .industory_box {
    padding: 40px 30px;
    margin-bottom: 16px;
  }
  .header-mobile {
    display: none;
  }
  .experience {
    margin-top: 8px;
  }
  .industory_paragraph {
    font-size: 16px;
    line-height: 150%;
  }
  .projects_box {
    padding: 40px 30px;
  }
  .items {
    font-size: 16px;
    margin-bottom: 0px;
    height: 80px;
  }
  .footer_heading {
    font-size: 40px;
    line-height: 150%;
  }
  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inquires_btn {
    margin-bottom: 32px;
  }
  .icon_top {
    flex-wrap: wrap;
  }
  .icon-box {
    margin-bottom: 30px;
  }
  .reserve_gap {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 24px;
  }
  .overview_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .discovery_box {
    grid-template-columns: repeat(1, 1fr);
  }
  .diggit_section {
    padding-top: 120px;
  }
  .banner-grid {
    grid-column: 3 / span 8;
    top: 0px;
  }
  .grid_banner {
    flex-direction: column-reverse;
    display: flex;
  }
  .list_grid {
    display: block;
    grid-column: auto;
  }
  .discovery_img-grid {
    position: relative;
    grid-column-start: auto;
    top: 0px;
    left: 0px;
  }
  .design_grid {
    grid-template-columns: auto;
  }
}
